import { BasicFetchResult } from "/@/api/model/baseModel";
import { IApiResult } from "/@/api/sys/model/admin-permissions-mode";
import { defHttp } from "/@/utils/http/axios";
import { IRolePermission } from "/@/views/admin-roles/types";

enum Api {
  basicPath = "/admin-roles",
}

/**
 * 删除一个role
 * @param id
 */
export function deleteRolePermission(id: string) {
  return defHttp.delete<IApiResult>({
    url: `${Api.basicPath}/${id}`,
  });
}

/**
 * 保存用户信息
 * @param id
 * @param params
 */
export function updateRolePermission(id: string, params: IRolePermission) {
  return defHttp.put<IRolePermission>({
    url: `${Api.basicPath}/${id}`,
    params,
  });
}

/**
 * 创建一个用户
 * @param params
 */
export function createRolePermission(params: IRolePermission) {
  return defHttp.post<IRolePermission>({
    url: Api.basicPath,
    params,
  });
}

/**
 * 获取所有角色的信息
 */
export function getRolePermissionList(params) {
  return defHttp.get<BasicFetchResult<IRolePermission[]>>({
    url: Api.basicPath,
    params,
  });
}

/**
 * 保存角色列表信息
 */
export function saveRolePermissionList(params: IRolePermission[]) {
  return defHttp.patch<IApiResult>({
    url: Api.basicPath,
    params,
  });
}
