import { useI18n } from "./useI18n";
import { InfoCircleFilled, CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons-vue";
import { Modal, message as Message, notification } from "ant-design-vue";
import type { ModalFunc, ModalFuncProps } from "ant-design-vue/lib/modal/Modal";
import { ArgsProps, ConfigProps } from "ant-design-vue/lib/notification";

export interface NotifyApi {
  info(config: ArgsProps): void;
  success(config: ArgsProps): void;
  error(config: ArgsProps): void;
  warn(config: ArgsProps): void;
  warning(config: ArgsProps): void;
  open(args: ArgsProps): void;
  close(key: String): void;
  config(options: ConfigProps): void;
  destroy(): void;
}

export declare type NotificationPlacement = "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
export declare type IconType = "success" | "info" | "error" | "warning";
export interface ModalOptionsEx extends Omit<ModalFuncProps, "iconType"> {
  iconType: "warning" | "success" | "error" | "info";
}
export type ModalOptionsPartial = Partial<ModalOptionsEx> & Pick<ModalOptionsEx, "content">;

interface ConfirmOptions {
  info: ModalFunc;
  success: ModalFunc;
  error: ModalFunc;
  warn: ModalFunc;
  warning: ModalFunc;
}

function getIcon(iconType: string) {
  if (iconType === "warning") {
    return <InfoCircleFilled class="modal-icon-warning" />;
  } else if (iconType === "success") {
    return <CheckCircleFilled class="modal-icon-success" />;
  } else if (iconType === "info") {
    return <InfoCircleFilled class="modal-icon-info" />;
  } else {
    return <CloseCircleFilled class="modal-icon-error" />;
  }
}

function renderContent({ content }: Pick<ModalOptionsEx, "content">) {
  return <div innerHTML={`<div>${content as string}</div>`}></div>;
}

/**
 * @description: Create confirmation box
 */
function createConfirm(options: ModalOptionsEx): ConfirmOptions {
  const iconType = options.iconType || "warning";
  Reflect.deleteProperty(options, "iconType");
  const opt: ModalFuncProps = {
    centered: true,
    icon: getIcon(iconType),
    ...options,
  };
  return Modal.confirm(opt) as unknown as ConfirmOptions;
}

const getBaseOptions = () => {
  const { t } = useI18n();
  return {
    okText: t("common.okText"),
    centered: true,
  };
};

function createModalOptions(options: ModalOptionsPartial, icon: string): ModalOptionsPartial {
  return {
    ...getBaseOptions(),
    ...options,
    content: renderContent(options),
    icon: getIcon(icon),
  };
}

function createSuccessModal(options: ModalOptionsPartial) {
  return Modal.success(createModalOptions(options, "success"));
}

function createErrorModal(options: ModalOptionsPartial) {
  return Modal.error(createModalOptions(options, "close"));
}

function createInfoModal(options: ModalOptionsPartial) {
  return Modal.info(createModalOptions(options, "info"));
}

function createWarningModal(options: ModalOptionsPartial) {
  return Modal.warning(createModalOptions(options, "warning"));
}

notification.config({
  placement: "topRight",
  duration: 3,
});

/**
 * @description: message
 */
export function useMessage() {
  return {
    createMessage: Message,
    notification: notification as NotifyApi,
    createConfirm: createConfirm,
    createSuccessModal,
    createErrorModal,
    createInfoModal,
    createWarningModal,
  };
}
