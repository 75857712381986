import { PageEnum } from "/@/enums/pageEnum";
import { PAGE_NOT_FOUND_ROUTE } from "/@/router/routes/basic";
import { FieldsStore } from "/@/store/modules/fields";
import { permissionStore } from "/@/store/modules/permission";
import { rolesStore } from "/@/store/modules/roles";
import { TagsStore } from "/@/store/modules/tags";
import { userStore } from "/@/store/modules/user";
import { usersStore } from "/@/store/modules/users";
import { VarsStore } from "/@/store/modules/variables";
import { isDevMode } from "/@/utils/env";
import { Role } from "/@/views/admin-roles/types";
import type { Router, RouteRecordRaw } from "vue-router";

const LOGIN_PATH = PageEnum.BASE_LOGIN;

const whitePathList: PageEnum[] = [LOGIN_PATH];

export function createPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    // Jump to the 404 page after processing the login
    if (from.path === LOGIN_PATH && to.name === PAGE_NOT_FOUND_ROUTE.name) {
      next(PageEnum.BASE_HOME);
      return;
    }

    // Whitelist can be directly entered
    if (whitePathList.includes(to.path as PageEnum)) {
      next();
      return;
    }

    const token = userStore.getTokenState;

    // token does not exist
    if (!token) {
      // You can access without permission. You need to set the routing meta.ignoreAuth to true
      if (
        to.meta.ignoreAuth
        // || to.name === FULL_PAGE_NOT_FOUND_ROUTE.name
      ) {
        next();
        return;
      }
      // redirect login page
      const redirectData: {
        path: string;
        replace: boolean;
        query?: Recordable<string>;
      } = {
        path: LOGIN_PATH,
        replace: true,
      };
      if (to.path) {
        redirectData.query = {
          ...redirectData.query,
          redirect: to.path,
        };
      }
      next(redirectData);
      return;
    }
    if (permissionStore.getIsDynamicAddedRouteState) {
      next();
      return;
    }

    //  每次刷新页面时都重置下
    const routes = await permissionStore.buildRoutesAction();
    routes.forEach((route) => {
      router.addRoute(route as unknown as RouteRecordRaw);
    });

    await userStore.getUserInfoAction({ _id: userStore.getUserInfoState._id });

    //  加载依赖数据
    let roles = userStore.getUserInfoState.roles;
    if (roles.includes(Role.LEADER) && !isDevMode()) {
      console.log(`当前账号为 ${Role.LEADER}, 将不会加载 fields 和 tags, 因此页面可能会有问题`);
    } else {
      if (userStore.getPageId.length > 0) {
        FieldsStore.getSystemFields.length === 0 && FieldsStore.loadFields();
        VarsStore.getVars.length === 0 && VarsStore.loadVars();
        TagsStore.getTags.length === 0 && TagsStore.loadTags();
      } else {
        console.log("未找到pageId");
      }
    }
    rolesStore.getRoles.length === 0 && rolesStore.loadRoles();
    usersStore.getUsers.length === 0 && usersStore.loadUsers();

    const redirectPath = (from.query.redirect || to.path) as string;
    const redirect = decodeURIComponent(redirectPath);
    const nextData = to.path === redirect ? { ...to, replace: true } : { path: redirect };
    permissionStore.commitDynamicAddedRouteState(true);
    next(nextData);
  });
}
