import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { hotModuleUnregisterModule } from '/@/utils/helper/vuexHelper';
import { IFields } from '/@/views/settings/fields/types';
import store from '/@/store';
import { FlowsStore } from '/@/store/modules/flows';
import { getAvailableFields } from '/@/api/settings/fields';

const NAME = 'fields';
hotModuleUnregisterModule(NAME);
@Module({ dynamic: true, namespaced: true, store, name: NAME })
export default class Fields extends VuexModule {
  //  列表数据 自定义字段
  private customFields: IFields[] = [];
  //  列表数据 系统字段
  private systemFields: IFields[] = [];
  //  字段正在加载中
  private fieldLoading: boolean = false;

  get getFieldLoading(): boolean {
    return this.fieldLoading;
  }

  get getCustomFields(): IFields[] {
    if (FlowsStore?.getSharePreviewData?.available_fields?.custom_field) {
      return FlowsStore?.getSharePreviewData?.available_fields?.custom_field;
    }
    return this.customFields;
  }

  get getSystemFields(): IFields[] {
    if (FlowsStore?.getSharePreviewData?.available_fields?.system_field) {
      return FlowsStore?.getSharePreviewData?.available_fields?.system_field;
    }
    return this.systemFields;
  }

  get getFields(): {
    custom: IFields[];
    system: IFields[];
  } {
    return {
      custom: this.customFields,
      system: this.systemFields,
    };
  }

  @Mutation
  commitSetFieldLoading(isLoading: boolean) {
    this.fieldLoading = isLoading;
  }

  @Mutation
  commitSetCustomFields(fields: IFields[]) {
    this.customFields = fields;
  }

  @Mutation
  commitSetSystemFields(fields: IFields[]) {
    this.systemFields = fields;
  }

  @Action
  async loadFields(): Promise<void> {
    this.commitSetFieldLoading(true);
    const { custom_field, system_field } = await getAvailableFields('all');
    this.commitSetCustomFields(custom_field ?? []);
    this.commitSetSystemFields(system_field ?? []);
    this.commitSetFieldLoading(false);
  }
}

export const FieldsStore = getModule<Fields>(Fields);
