import { BasicFetchResult } from "/@/api/model/baseModel";
import { defHttp } from "/@/utils/http/axios";
import { IPage } from "/@/views/pages/types";
import { IVar } from "/@/views/settings/variables/types";

enum Vars {
  basicPath = "/variables",
}

/**
 * 获取 vars 列表
 * @param params
 */
export function getVarsList(params) {
  return defHttp.get<BasicFetchResult<IVar[]>>({
    url: Vars.basicPath,
    params,
  });
}

/**
 * 创建一个 vars
 * @param params
 */
export function createVar(params: Omit<IVar, "_id"> & { page_id: string }) {
  return defHttp.post({
    url: Vars.basicPath,
    params: params,
  });
}

/**
 * 更新一个 vars
 * @param params
 */
export function updateVar(params: Omit<IVar, "_id"> & { page_id: string }) {
  return defHttp.patch({
    url: `${Vars.basicPath}/${params._id}`,
    params: params,
  });
}

/**
 * 删除一个var
 * @param id
 */
export function deleteVar(id: string) {
  return defHttp.delete({
    url: `${Vars.basicPath}/${id}`,
  });
}

/**
 * 获取z自定义变量使用的对应的列表
 * @param id
 */
export function getVarsUsesList(id: string) {
  return defHttp.get({
    url: `${Vars.basicPath}/${id}/getUsesList`,
  });
}
