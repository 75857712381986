import { createHttpGuard } from "./httpGuard";
import { createMessageGuard } from "./messageGuard";
import { createPageGuard } from "./pageGuard";
import { createPageLoadingGuard } from "./pageLoadingGuard";
import { createPermissionGuard } from "./permissionGuard";
import { createProgressGuard } from "./progressGuard";
import { createScrollGuard } from "./scrollGuard";
import { createStateGuard } from "./stateGuard";
import router from "/@/router";
import { createPagesGuard } from "/@/router/guard/pagesGuard";

createPageGuard(router);
createPageLoadingGuard(router);
createHttpGuard(router);
createScrollGuard(router);
createMessageGuard(router);
createProgressGuard(router);
createPermissionGuard(router);
createStateGuard(router);
createPagesGuard(router);
