import { BasicFetchResult } from "/@/api/model/baseModel";
import { defHttp } from "/@/utils/http/axios";
import { IUser } from "/@/views/users/types";

enum Api {
  basicPath = "/users",
}

/**
 * 获取所有用户
 */
export function getAllUsers() {
  return defHttp.get<BasicFetchResult<IUser[]>>({
    url: `${Api.basicPath}/all`,
  });
}

/**
 * 获取用户列表
 * @param params
 */
export function getUsersList(params) {
  return defHttp.get<BasicFetchResult<IUser>>({
    url: Api.basicPath,
    params,
  });
}

/**
 * 保存用户token
 * @param userId
 * @param token
 */
export function saveToken(userId: string, token: string) {
  return defHttp.put({
    url: `${Api.basicPath}/${userId}/saveToken`,
    params: {
      token,
    },
  });
}

/**
 * 创建用户
 * @param params
 */
export function createUser(params) {
  return defHttp.post({
    url: Api.basicPath,
    params,
  });
}

/**
 * 删除用户
 * @param userId
 */
export function deleteUser(userId: string) {
  return defHttp.delete({
    url: `${Api.basicPath}/${userId}`,
  });
}

/**
 * 更新用户信息
 * @param userId
 * @param params
 */
export function updateUser(userId: string, params: any) {
  return defHttp.put({
    url: `${Api.basicPath}/${userId}`,
    params,
  });
}

/**
 * 获取指定用户具体信息
 * @param userId
 */
export function getUserInfo(userId: string) {
  return defHttp.get({
    url: `${Api.basicPath}/${userId}/edit`,
  });
}

/**
 * 切换分页
 * @param userId
 * @param pageId
 */
export function switchPage(userId: string, pageId: string) {
  return defHttp.put({
    url: `${Api.basicPath}/${userId}/switchPage`,
    params: {
      page_id: pageId,
    },
  });
}

/**
 * 更新开启关闭通知
 * @param userId
 * @param params
 */
export function setErrorNotify(userId: string, params: any) {
  return defHttp.post({
    url: `${Api.basicPath}/${userId}/setErrorNotify`,
    params: params,
  });
}

export function setWidgetErrorNotify(userId: string, params: any) {
  return defHttp.post({
    url: `${Api.basicPath}/${userId}/setWidgetErrorNotify`,
    params: params,
  });
}

/**
 * 获取管理员账户分享的免登录链接
 */
export function getGuestLink() {
  return defHttp.get({
    url: `${Api.basicPath}/hash`,
  });
}
