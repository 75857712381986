interface IPermissionItem {
  id: number;
  name: string;
  pivot?: any;
}

export interface IRolePermission {
  name: string;
  _id: string;
  routerPermissions: string[];
  apiPermission: string[];
  slug: string;
  permissions: IPermissionItem[];
}

export enum Role {
  SUPER_ADMIN = "super_admin", // 超级管理员
  LEADER = "leader", // 组长
  ADMIN = "admin", // 专页管理员
  USER = "user", // 是普通用户
  AGENT = "livechat_agent", //  畅聊人员
}
