import { userStore } from "/@/store/modules/user";
import { Role } from "/@/views/admin-roles/types";
import type { Router } from "vue-router";

export function createPagesGuard(router: Router) {
  const redirectPath = `/pages/unconnected`;
  router.beforeEach(async (to, from, next) => {
    //  如果当前用户pages
    if ([redirectPath, "/login", "/pages/manage", "/guest/home"].includes(to.path)) {
      next();
      return;
    }
    //  如果是组长或者超级管理员, 不进行限制
    let roles = userStore.getUserInfoState.roles;
    if (roles.includes(Role.SUPER_ADMIN) || roles.includes(Role.LEADER)) {
      next();
      return;
    }

    //  如果当前用户是畅聊人员, 只允许进入订阅者路由
    if (roles.includes(Role.AGENT) && ["subscribers", "home"].includes(to.name)) {
      next();
      return;
    }

    if (userStore.getUserInfoState.pages.length === 0) {
      if (to.path === redirectPath) {
        next();
      } else {
        next({
          path: redirectPath,
          replace: true,
        });
      }
    } else {
      next();
    }
  });
}
