/**
 * Multi-language related operations
 */
import { i18n } from "./setupI18n";
import type { LocaleType } from "/#/config";
import { localeStore } from "/@/store/modules/locale";
import dayjs from "dayjs";
import { unref, computed } from "vue";

interface LangModule {
  message: Recordable;
  dayLocal: Recordable;
  dayLocalName: string;
}

const loadLocalePool: LocaleType[] = [];

function setI18nLanguage(locale: LocaleType) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    (i18n.global.locale as any).value = locale;
  }
  localeStore.setLocaleInfo({ locale });
  document.querySelector("html")?.setAttribute("lang", locale);
}

export function useLocale() {
  const getLocale = computed(() => localeStore.getLocale);
  const getShowLocalePicker = computed(() => localeStore.getShowPicker);

  const getAntdLocale = computed(() => {
    return i18n.global.getLocaleMessage(unref(getLocale))?.antdLocale;
  });

  // Switching the language will change the locale of useI18n
  // And submit to configuration modification
  async function changeLocale(locale: LocaleType) {
    const globalI18n = i18n.global;
    const currentLocale = unref(globalI18n.locale);
    if (currentLocale === locale) return locale;

    if (loadLocalePool.includes(locale)) {
      setI18nLanguage(locale);
      return locale;
    }
    const langModule = ((await import(`./lang/${locale}.ts`)) as any).default as LangModule;
    if (!langModule) return;

    const { message, dayLocal, dayLocalName } = langModule;

    globalI18n.setLocaleMessage(locale, message);
    dayjs.updateLocale(dayLocalName, dayLocal);
    loadLocalePool.push(locale);

    setI18nLanguage(locale);
    return locale;
  }

  return {
    getLocale,
    getShowLocalePicker,
    changeLocale,
    getAntdLocale,
  };
}
