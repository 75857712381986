import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {FlowRouterNamesEnum, TSharePreviewData} from '/@/views/automation/flows/edit/types';
import {hotModuleUnregisterModule} from '/@/utils/helper/vuexHelper';
import store from '/@/store';
import {
  changeFlowName,
  getFlowEdit,
  getFlowSharePreview,
  publishFlow,
  revertPublished,
  saveDraftFlow,
} from '/@/api/automation/flows';
import {
  buildDataForDraftApiRequest,
  deepClone,
  formatDataFromApi,
} from '/@/views/automation/flows/edit/utils';
import {MSG} from '/@/views/automation/flows/edit/components/actionBar/validate';

const NAME = 'flows';
hotModuleUnregisterModule(NAME);
@Module({dynamic: true, namespaced: true, store, name: NAME})
export default class Flows extends VuexModule {
  //  当前 flow 的命名空间
  private namespace = ''
  //  api返回的数据, 未经过处理
  private originalData = {};
  //  经过处理后的数据
  private flowData = null;
  //  经过处理后的草稿数据
  private flowDraftData = null;
  //  是否为禁用状态
  private isReadonly = false;
  //  是否正在保存
  private isSaving = false;
  //  flow是否正在更新, 该更新禁止用户操作页面
  private isLoading = false;
  //  当前是否未发布
  private isUnPublish = false;
  //  统计数据
  private stats = {};
  //  当前flow的类型: 预览, 编辑, 分享
  private flowViewType:
    | FlowRouterNamesEnum.REVIEW
    | FlowRouterNamesEnum.EDIT
    | FlowRouterNamesEnum.SHARE_PREVIEW = FlowRouterNamesEnum.REVIEW;
  //  保存后台返回的分享数据
  //  这个数据会用于页面显示以及一些列的判断
  private sharePreviewData: Nullable<TSharePreviewData> = null;
  //  保存每次用户点击发布时，被拒绝发布的 msg
  //  这些 msg 将用于用户点击 node 后显示的侧边栏中
  private deniedNodesMsg: MSG[] = [];
  //  当用户粘贴从其他站点来的 nodes 时
  //  服务器需要处理一段时间
  //  因此需要下面这个值来显示一个全屏的 overlay
  private copyNodesProcessing: boolean = false

  get getCopyNodesProcessing() {
    return this.copyNodesProcessing
  }

  get getNamespace() {
    return this.namespace
  }

  get getDeniedNodesMsg() {
    return this.deniedNodesMsg;
  }

  get getSharePreviewData() {
    return this.sharePreviewData;
  }

  get getFlowViewType() {
    return this.flowViewType;
  }

  get getIsUnPublish() {
    return this.isUnPublish;
  }

  get getOriginalData() {
    return this.originalData;
  }

  get getIsLoading() {
    return this.isLoading;
  }

  get getIsSaving() {
    return this.isSaving;
  }

  get getIsReadonly() {
    return this.isReadonly;
  }

  get getFlowData() {
    return this.flowData;
  }

  get getDraftData() {
    return this.flowDraftData;
  }

  get getStats() {
    return this.stats;
  }

  @Mutation
  commitSetCopyNodesProcessing(bool) {
    this.copyNodesProcessing = bool
  }

  @Mutation
  commitSetNamespace(namespace) {
    this.namespace = namespace
  }

  @Mutation
  commitSetSharePreviewData(payload: TSharePreviewData | null) {
    this.sharePreviewData = payload;
  }

  @Mutation
  commitSetFlowViewType(type) {
    this.flowViewType = type;
  }

  @Mutation
  commitSetIsUnPublish(isUnPublish: boolean) {
    this.isUnPublish = isUnPublish;
  }

  @Mutation
  commitSetIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  commitSetIsReadonly(isReadonly: boolean) {
    this.isReadonly = isReadonly;
  }

  @Mutation
  commitSetOriginalData(data) {
    this.originalData = data;
  }

  @Mutation
  commitSetFlowData(data) {
    this.flowData = data;
  }

  @Mutation
  commitSetFlowDraftData(data) {
    this.flowDraftData = data;
  }

  @Mutation
  commitSetIsSaving(isSaving: boolean) {
    this.isSaving = isSaving;
  }

  @Mutation
  commitSetFlowName(name: string) {
    this.originalData.name = name;
  }

  @Mutation
  commitSetStats(stats: any) {
    this.stats = stats;
  }

  @Mutation
  commitSetDeniedNodesMsg(msg: MSG[]) {
    this.deniedNodesMsg = msg;
  }

  @Action
  updateDeniedNodesMsg(msg: MSG[]) {
    this.commitSetDeniedNodesMsg(msg);
  }

  @Action
  async loadFlowEdit(payload: {
    flowId: string;
    viewType:
      | FlowRouterNamesEnum.REVIEW
      | FlowRouterNamesEnum.EDIT
      | FlowRouterNamesEnum.SHARE_PREVIEW;
  }): Promise<void> {
    const {flowId, viewType} = payload;
    let data: any = null;
    this.commitSetIsLoading(true);
    this.commitSetSharePreviewData(null);

    //  根据不同的访问类型加载不同的数据
    //  1. /automation/flows/c0046690-db95-45ed-bb30-ae2e9ab8e4a1 预览页面  FlowsEditReview
    //  2. /automation/flows/c0046690-db95-45ed-bb30-ae2e9ab8e4a1/edit 编辑页面  FlowsEdit
    //  3. /automation/flows/c0046690-db95-45ed-bb30-ae2e9ab8e4a1/sharePreview 分享页面  FlowsEditSharePreview
    //  FlowsEditReview 和 FlowsEdit 是同一套数据, 可以调取同一个接口, 但是 FlowsEditSharePreview 需要调取分享接口, 并且返回的内部数据还有些区别
    if ([FlowRouterNamesEnum.SHARE_PREVIEW].includes(viewType)) {
      let res = await getFlowSharePreview(flowId);
      this.commitSetNamespace(res.namespace)
      this.commitSetSharePreviewData(res);
      data = res.flow;
    } else if (Object.values(FlowRouterNamesEnum).includes(viewType)) {
      data = await getFlowEdit(flowId);
      this.commitSetNamespace(data.namespace)
    } else {
      return Promise.reject(`未知的FlowViewType类型: ${viewType}`);
    }
    updateIsUnPublishByData(data);
    this.commitSetOriginalData(deepClone(data));
    let {nodes, draftNodes} = formatDataFromApi(deepClone(data));
    this.commitSetStats(deepClone(data).stats ?? {});
    this.commitSetFlowData(deepClone(nodes));
    this.commitSetFlowDraftData(deepClone(draftNodes));
    this.commitSetIsLoading(false);
    return Promise.resolve();
  }

  /**
   * 更新flow数据
   * @param data
   */
  @Action
  async updateFlowData(data: any): Promise<void> {
    this.commitSetIsSaving(true);
    const buildedData = buildDataForDraftApiRequest(deepClone(this.originalData), data);
    await saveDraftFlow(this.originalData._id, buildedData);
    this.commitSetIsUnPublish(true);
    this.commitSetIsSaving(false);
    return Promise.resolve();
  }

  @Action
  async publish(data: any): Promise<void> {
    this.commitSetIsLoading(true);
    const buildedData = buildDataForDraftApiRequest(deepClone(this.originalData), data);
    let publishData = {
      nodes: buildedData.draft_batch.nodes,
      root_node_id: buildedData.draft_batch.root_node_id,
      triggers: buildedData.triggers,
    };

    const res = await publishFlow(this.originalData._id, publishData);
    updateIsUnPublishByData(res);
    this.commitSetIsLoading(false);
    return Promise.resolve();
  }

  /**
   * 回复到发布前状态
   */
  @Action
  async revert(): Promise<void> {
    this.commitSetIsLoading(true);
    await revertPublished(this.originalData._id);
    this.commitSetIsLoading(false);
    return Promise.resolve();
  }

  @Action
  async changeFlowName(name: string) {
    await changeFlowName(this.originalData._id, name);
    this.commitSetFlowName(name);
  }

  @Action
  async changeCopyNodesProcessingState(bool: boolean) {
    this.commitSetCopyNodesProcessing(bool)
  }
}

export const FlowsStore = getModule<Flows>(Flows);

/**
 * 通过data整体数据,更新isUnPublish状态
 * @param data
 */
function updateIsUnPublishByData(data: any) {
  if (data.draft_batch.nodes) {
    FlowsStore.commitSetIsUnPublish(Object.keys(data.draft_batch.nodes).length > 0);
  } else {
    FlowsStore.commitSetIsUnPublish(false);
  }
}
