import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {hotModuleUnregisterModule} from '/@/utils/helper/vuexHelper';
import {IVar} from '/@/views/settings/variables/types';
import store from '/@/store';
import {getVarsList} from '/@/api/settings/variables';

const NAME = 'variables';
hotModuleUnregisterModule(NAME);
@Module({dynamic: true, namespaced: true, store, name: NAME})
export default class Vars extends VuexModule {
  //  自定义变量数据
  private vars: IVar[] = [];
  //  自定义变量正在加载中
  private varsLoading: boolean = false;

  get getVarsLoading(): boolean {
    return this.varsLoading;
  }

  get getVars(): IVar[] {
    return this.vars
  }

  @Mutation
  commitSetVarsLoading(isLoading: boolean) {
    this.varsLoading = isLoading;
  }

  @Mutation
  commitSetVars(vars: IVar[]) {
    this.vars = vars;
  }

  @Action
  async loadVars(): Promise<void> {
    this.commitSetVarsLoading(true);
    const {items} = await getVarsList({});
    this.commitSetVars(items);
    this.commitSetVarsLoading(false);
  }
}

export const VarsStore = getModule<Vars>(Vars);
