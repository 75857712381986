import { FlowsStore } from "/@/store/modules/flows";
import { FlowCompType } from "/@/views/automation/flows/edit/types";

/**
 * 获取一个节点的后代节点中的指定节点数量
 * @param options
 * @param foundNodes
 */
export function getAllSpecificDescendantsNode(
  options: Partial<{
    node: any;
    types: FlowCompType;
    excludesOutputsCallback(type: FlowCompType, key: string): boolean;
    nodes: any;
  }>,
  foundNodes: any[] = []
) {
  foundNodes = foundNodes ? foundNodes : [];

  Object.keys(options.node.outputs).map((key) => {
    //  判断是否要验证该 output
    if (
      options.excludesOutputsCallback &&
      !options.excludesOutputsCallback(options.node.data.type as FlowCompType, key)
    )
      return;

    if (options.node.outputs[key].connections.length === 0) return;

    let childNodeId = options.node.outputs[key].connections[0].node;
    let target = options.nodes.find((node) => node.id === childNodeId);

    if (target.data.type === options.types) {
      foundNodes.push(target);
    }

    getAllSpecificDescendantsNode(
      Object.assign({}, options, {
        node: target,
      }),
      foundNodes
    );
  });

  return foundNodes;
}

/**
 * 格式化保存草稿数据
 * @param originalData
 * @param data
 */
export function buildDataForDraftApiRequest(originalData, data) {
  //  找到触发器id
  let trigger = Object.values(data.nodes).find((node) => node.data.type === FlowCompType.TRIGGER);
  if (!trigger) {
    throw new Error("更新草稿失败, trigger未找到");
    return;
  }

  //  更新triggers里面的nodeData
  let nodeData = deepClone(trigger);
  nodeData.data.payload = [];
  originalData.triggers = originalData.triggers ?? {};
  originalData.triggers.nodeData = nodeData;

  //  更新triggers
  //  根据不同类型存放数据
  let widgets = [];
  let anotherFlow = [];
  let keywords = [];
  trigger.data.payload.map((w) => {
    if (w.type === "another_flow") {
      anotherFlow.push(w);
    } else if (w.type === "keywords") {
      keywords.push(w);
    } else {
      widgets.push(w);
    }
  });
  originalData.triggers.widgets = widgets;
  originalData.triggers.another_flow = anotherFlow;
  originalData.triggers.keywords = keywords;

  //  更新draft root_node_id
  originalData.draft_batch.root_node_id = trigger.outputs.next_step.connections[0]
    ? trigger.outputs.next_step.connections[0].node
    : "";

  //  更新nodes
  let nodesList = Object.values(data.nodes).filter(
    (node) => node.data.type !== FlowCompType.TRIGGER
  );

  originalData.draft_batch.nodes = {};
  nodesList.map((n) => {
    originalData.draft_batch.nodes[n.id] = n;
  });

  return {
    triggers: originalData.triggers,
    draft_batch: originalData.draft_batch,
  };
}

/**
 * 格式化从api返回的数据
 * @param data
 */
export function formatDataFromApi(data) {
  let id = "chatbot@0.0.1";
  let nodes = {
    id,
    nodes: {},
  };
  let draftNodes = {
    id,
    nodes: {},
  };

  //  如果id不存在这代表着没有数据
  if (!data.triggers?.nodeData.id) {
    return {
      nodes,
      draftNodes,
    };
  }

  //  根据不同类型存放数据
  data.triggers.another_flow.map((item) => {
    item.type = "another_flow";
  });
  data.triggers.keywords.map((item) => {
    item.type = "keywords";
  });

  //  处理triggers
  let triggersPayload = [
    ...data.triggers.widgets,
    ...data.triggers.keywords,
    ...data.triggers.another_flow,
  ];
  data.triggers.nodeData.data.payload = triggersPayload;
  nodes.nodes[data.triggers.nodeData.id] = data.triggers.nodeData;
  // nodes.nodes[data.triggers.nodeData.id].outputs.next_step.connections[0] = data.root_node_id;
  //  遍历nodes
  Object.values(data.nodes).map((n) => {
    nodes.nodes[n.id] = n;
  });

  //  处理备份数据
  draftNodes.nodes[data.triggers.nodeData.id] = data.triggers.nodeData;
  /*draftNodes.nodes[data.triggers.nodeData.id].outputs.next_step.connections[0] =
    data.draft_batch.root_node_id && data.draft_batch.root_node_id.length > 0
      ? data.draft_batch.root_node_id
      : data.root_node_id;*/
  Object.values(data.draft_batch.nodes).map((n) => {
    draftNodes.nodes[n.id] = n;
  });

  //  如果draft_batch 里面nodes没有值 (默认会有个触发器)
  //  将nodes里面的数据放一份到draft_batch里面
  if (Object.values(draftNodes.nodes).length <= 1) {
    draftNodes.nodes = deepClone(nodes.nodes);
  }

  return {
    nodes,
    draftNodes,
  };
}

export function deepClone<T>(data: T): T {
  return JSON.parse(JSON.stringify(data));
}

/**
 * 从 stats 数据中获取指定 id 的统计数据值
 * @param id
 * @param stats
 */
export function getStats(id: string, stats: object) {
  if (stats[id]) {
    return stats[id];
  }
  return null;
}

/**
 * 获取列表点击统计
 * @param id
 * @param stats
 */
export function getStatsClicked(id: string, stats: object) {
  let statsObj = getStats(id, stats);
  if (statsObj) {
    return statsObj.clicked;
  } else {
    return 0;
  }
}

/**
 * 获取发送消息统计
 * @param id
 * @param stats
 */
export function getSendMessageStats(id) {
  let statsObj = getStats(id, FlowsStore.getStats);
  if (statsObj) {
    return statsObj;
  } else {
    return {
      sent: 0,
      clicked: 0,
    };
  }
}

/**
 * 获取按钮数据统计
 * @param id
 */
export function getButtonStats(id) {
  let statsObj = getStats(id, FlowsStore.getStats);
  if (statsObj) {
    return statsObj;
  } else {
    return {
      sent: 0,
      clicked: 0,
      percent: "0%",
    };
  }
}
