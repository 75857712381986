import store from '/@/store/index';
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { hotModuleUnregisterModule } from '/@/utils/helper/vuexHelper';
import { IUser } from '/@/views/users/types';
import { getAllUsers } from '/@/api/users/users';

const NAME = 'users';
hotModuleUnregisterModule(NAME);
@Module({ namespaced: true, name: NAME, dynamic: true, store })
class Users extends VuexModule {
  private users: IUser[] = [];

  get getUsers() {
    if (this.users.length === 0) {
      usersStore.loadUsers();
    }
    return this.users;
  }

  @Mutation
  commitSetUsers(users: IUser[]) {
    this.users = users;
  }

  @Action
  async loadUsers() {
    let items: IUser[] = await getAllUsers();
    this.commitSetUsers(items);
  }
}

export const usersStore = getModule<Users>(Users);
