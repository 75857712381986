const prefix: string = "chatbot-";

/**
 * 保存 localstorage
 * @param key
 * @param value
 */
export function set_storage(key: string, value: any): void {
  if ("localStorage" in window) {
    try {
      window.localStorage.setItem(`${prefix}${key}`, value);
    } catch (error) {
      console.log(error);
    }
  }
}

/**
 * 获取 localstorage
 * @param {string} key
 * @returns {string}
 */
export function get_storage(key: string): any {
  if ("localStorage" in window) {
    try {
      return window.localStorage.getItem(`${prefix}${key}`);
    } catch (error) {
      console.log(error);
    }
  }
}
