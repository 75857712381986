import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { hotModuleUnregisterModule } from '/@/utils/helper/vuexHelper';
import { ITags } from '/@/views/settings/tags/types';
import store from '/@/store';
import { getTags } from '/@/api/settings/tags';
import { FlowsStore } from '/@/store/modules/flows';

const NAME = 'tags';
hotModuleUnregisterModule(NAME);
@Module({ dynamic: true, namespaced: true, store, name: NAME })
export default class Tags extends VuexModule {
  //  tags列表
  private tags: ITags[] = [];
  //  tags正在加载中
  private tagsLoading: boolean = false;

  get getTagsLoading(): boolean {
    return this.tagsLoading;
  }

  get getTags(): ITags[] {
    if (FlowsStore?.getSharePreviewData?.tags) {
      return FlowsStore?.getSharePreviewData?.tags;
    }
    return this.tags;
  }

  @Mutation
  commitSetTagsLoading(isLoading: boolean) {
    this.tagsLoading = isLoading;
  }

  @Mutation
  commitSetTags(tags: ITags[]) {
    this.tags = tags;
  }

  @Action
  async loadTags(): Promise<void> {
    this.commitSetTagsLoading(true);
    const { items } = await getTags({ page: 1, pageSize: 999 });
    this.commitSetTags(items);
    this.commitSetTagsLoading(false);
  }
}

export const TagsStore = getModule<Tags>(Tags);
