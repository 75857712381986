import type {
  LoginParams,
  GetUserInfoByUserIdModel,
  GetUserInfoByUserIdParams,
} from '/@/api/sys/model/userModel';
import type { UserInfo } from '/@/store/types';

import store from '/@/store/index';
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { hotModuleUnregisterModule } from '/@/utils/helper/vuexHelper';
import { getSearchParams } from '/@/utils';
import { deepClone } from '/@/views/automation/flows/edit/utils';

import { PageEnum } from '/@/enums/pageEnum';
import { RoleEnum } from '/@/enums/roleEnum';
import { ROLES_KEY, TOKEN_KEY, USER_INFO_KEY } from '/@/enums/cacheEnum';
import { set_storage, get_storage } from '/@/utils/storage';
import { useMessage } from '/@/hooks/web/useMessage';

import router from '/@/router';

import { loginApi, getUserInfoById, updatePageNote } from '/@/api/sys/user';

import { useI18n } from '/@/hooks/web/useI18n';
import { ErrorMessageMode } from '/@/utils/http/axios/types';
import { getAuthCache, setAuthCache } from '/@/utils/auth/index';
import { set } from 'lodash-es';
import { checkUserSubscription } from '/@/views/settings/basic-setting/components/notification/helper';
import { IPage } from '/@/views/pages/types';
import { deleteAllCookies } from '/@/utils/auth';

const NAME = 'app-user';
hotModuleUnregisterModule(NAME);

@Module({ namespaced: true, name: NAME, dynamic: true, store })
class User extends VuexModule {
  // user info
  private userInfoState: UserInfo | null = null;

  // token
  private tokenState = '';

  // roleList
  private roleListState: RoleEnum[] = [];

  private pageId: string = '';

  get getPageId(): string {
    let pageId = this.pageId.length === 0 ? get_storage('pageId') ?? '' : this.pageId;
    //  1. 当前如果没有pageId的值, 默认返回pages第一个, 并且保存当前的值
    if (pageId.length === 0) {
      //  a. 如果当前数据中存在第一个
      if (this.getUserInfoState.pages && this.getUserInfoState.pages.length > 0) {
        setPageId(this.getUserInfoState.pages[0].page_id);
        return this.getUserInfoState.pages[0].page_id;
      } else {
        console.log('未找到page_id');
        return '';
      }
    } else {
      //  2. 如果当前存在pageId, 判断该Id是否在userInfo数组里面
      if (this.getUserInfoState?.pages && this.getUserInfoState?.pages.length > 0) {
        //  a. 如果当前userInfo pages里面能找到该Id, 直接返回
        if (this.getUserInfoState.pages.find((i) => i.page_id === pageId)) {
          setPageId(pageId, false);
          return pageId;
        } else {
          //  b. 如果当前UserInfo里面找不到当前Id, 默认返回第一个
          let firstPage: any = this.getUserInfoState?.pages[0];
          setPageId(firstPage.page_id);
          return firstPage.page_id;
        }
      } else {
        //  c. 如果当前没有userInfo
        console.log('当前用户的UserInfo中未设置pages');
        return '';
      }
    }
  }

  get getCurrentPage(): IPage | undefined {
    const pageId = this.getPageId;
    return this.getUserInfoState.pages.find((i) => i.page_id === pageId);
  }

  get getUserInfoState(): UserInfo {
    return this.userInfoState || getAuthCache<UserInfo>(USER_INFO_KEY) || {};
  }

  get getTokenState(): string {
    return this.tokenState || getAuthCache<string>(TOKEN_KEY);
  }

  get getUserId(): string {
    return this.getUserInfoState._id;
  }

  get getRoleListState(): RoleEnum[] {
    return this.roleListState.length > 0 ? this.roleListState : getAuthCache<RoleEnum[]>(ROLES_KEY);
  }

  @Mutation
  commitResetState(): void {
    this.userInfoState = null;
    this.tokenState = '';
    this.roleListState = [];
  }

  @Mutation
  commitUserInfoState(info: UserInfo): void {
    this.userInfoState = info;
    setAuthCache(USER_INFO_KEY, info);
  }

  @Mutation
  commitRoleListState(roleList: RoleEnum[]): void {
    this.roleListState = roleList;
    setAuthCache(ROLES_KEY, roleList);
  }

  @Mutation
  commitTokenState(info: string): void {
    this.tokenState = info;
    setAuthCache(TOKEN_KEY, info);
  }

  @Mutation
  commitSetPageId(pageId: string): void {
    this.pageId = pageId;
  }

  @Mutation
  commitUserInfoUpdate(payload) {
    this.userInfoState = this.userInfoState || getAuthCache<UserInfo>(USER_INFO_KEY) || {};
    set(this.userInfoState, payload.path, payload.data);
  }

  /**
   * @description: login
   */
  @Action
  async login(
    params: LoginParams & {
      goHome?: boolean;
      mode?: ErrorMessageMode;
    }
  ): Promise<GetUserInfoByUserIdModel | null> {
    try {
      const { goHome = true, mode, ...loginParams } = params;
      const data = await loginApi(loginParams, mode);
      const { csrf_token: token, _id } = data;

      // save token
      this.commitTokenState(token);

      // get user info
      const userInfo = await this.getUserInfoAction({ _id });

      goHome && (await router.replace(PageEnum.BASE_HOME));
      return userInfo;
    } catch (error) {
      return null;
    }
  }

  @Action
  async getUserInfoAction({ _id }: GetUserInfoByUserIdParams) {
    const userInfo = await getUserInfoById({ _id });
    const { roles } = userInfo;
    const roleList = roles.map((item) => item.value) as RoleEnum[];

    //  如果当前路由中存在#change_to_page hash 时候, 切换到指定专页
    //  此步骤是为了兼容, 分享专页保存到指定专页后, 点击查看时, 专页未切换的问题
    //  因此导致访问的链接是正确的, 但是新开页面的缓存里面的pageId还是老的
    //  导致 新开页面的flow不在当前缓存的pageId中
    //  此兼容方式最终的解决方法是 在路由中添加  /pageid/ 目录结构
    let hashObj = new URLSearchParams(window.location.hash.substring(1));
    let changeToPageId = hashObj.get('change_to_page');
    if (changeToPageId && userInfo.pages.find((item) => item.page_id === changeToPageId)) {
      this.setPageId(changeToPageId);
    }

    // userInfo.pages = sortPages(deepClone(userInfo.pages));
    this.commitUserInfoState(userInfo);
    this.commitRoleListState(roleList);

    locateLogs();

    //  判断用户是否订阅, 如果订阅的话, 自动发送 endpoint
    if (userInfo.is_error_notify) {
      setTimeout(() => {
        checkUserSubscription(_id, userInfo.public_key);
      }, 5000);
    }

    return userInfo;
  }

  @Action
  async updateUserInfo(payload: { data: any; path: string }) {
    this.commitUserInfoUpdate(payload);
  }

  /**
   * @description: logout
   */
  @Action
  async logout(goLogin = false) {
    deleteAllCookies();
    goLogin && router.push(PageEnum.BASE_LOGIN);
  }

  /**
   * @description: Confirm before logging out
   */
  @Action
  async confirmLoginOut() {
    const { createConfirm } = useMessage();
    const { t } = useI18n();
    createConfirm({
      iconType: 'warning',
      title: t('sys.app.logoutTip'),
      content: t('sys.app.logoutMessage'),
      onOk: async () => {
        await this.logout(true);
      },
    });
  }

  @Action
  setPageId(pageId: string) {
    set_storage('pageId', pageId);
    this.commitSetPageId(pageId);
  }

  @Action
  setPageIdWithoutLocalStorage(pageId: string) {
    this.commitSetPageId(pageId);
  }

  @Action
  setPageNote(payload) {
    const { pageId, pageNote } = payload;
    updatePageNote(pageId, pageNote);
  }
}
export const userStore = getModule<User>(User);

/**
 * 定位logs, 此处存在切换page问题
 */
function locateLogs() {
  let params = getSearchParams();
  if (params.page_id && params.log_id) {
    userStore.setPageId(params.page_id);
    window.location.hash = '';
    window.location.href = `/settings/logs#log_id=${params.log_id}`;
    window.location.reload();
  }
}

function setPageId(pageId: string, isUseLocalStorage: boolean = true) {
  isUseLocalStorage ? userStore.setPageId(pageId) : userStore.setPageIdWithoutLocalStorage(pageId);
}

/**
 * 根据当前缓存中的page_id, 排序当前pages
 * @param pages
 */
function sortPages(pages: any[]) {
  // 1. 如果当前不存在pageId, 直接返回
  if (userStore.getPageId.length === 0) {
    return pages;
  }

  //  2. 如果当前在pages里面找不到对应的pageId
  if (!pages.find((p) => p.page_id === userStore.getPageId)) {
    return pages;
  }

  //  3. 如果pages为空直接返回
  if (pages.length === 0) {
    return pages;
  }

  //  4. 将pageId对应的page, 放到数组最前面 (正常情况)
  let current = pages.find((p) => p.page_id === userStore.getPageId);
  let index = pages.indexOf(current);
  let currentPage = pages.splice(index, 1);
  pages.unshift(currentPage[0]);
  return pages;
}
