<script lang="ts">
  import { defineComponent, h, unref, computed } from 'vue';

  import { Popconfirm } from 'ant-design-vue';

  import BasicButton from './BasicButton.vue';

  import { propTypes } from '/@/utils/propTypes';
  import { extendSlots } from '/@/utils/helper/tsxHelper';
  import { omit } from 'lodash-es';

  import { useAttrs } from '/@/hooks/core/useAttrs';
  import { useI18n } from '/@/hooks/web/useI18n';

  export default defineComponent({
    name: 'PopButton',
    components: { Popconfirm, BasicButton },
    inheritAttrs: false,
    props: {
      size: propTypes.oneOf(['large', 'default', 'small']).def(),
      enable: propTypes.bool.def(true),
      okText: propTypes.string,
      cancelText: propTypes.string,
    },
    setup(props, { slots }) {
      const { t } = useI18n();
      const attrs = useAttrs();

      const getBindValues = computed(() => {
        const popValues = Object.assign(
          {
            okText: t('common.okText'),
            cancelText: t('common.cancelText'),
          },
          { ...props, ...unref(attrs) }
        );
        return popValues;
      });

      return () => {
        const bindValues = omit(unref(getBindValues), 'icon');
        const btnBind = omit(bindValues, 'title') as Recordable;
        if (btnBind.disabled) btnBind.color = '';
        const Button = h(BasicButton, btnBind, extendSlots(slots));
        // If it is not enabled, it is a normal button
        if (!props.enable) {
          return Button;
        }

        if (bindValues?.title && typeof bindValues.title === 'function') {
          let titleSlot = bindValues.title;
          delete bindValues.title;
          return h(Popconfirm, bindValues, { default: () => Button, title: titleSlot });
        }
        return h(Popconfirm, bindValues, { default: () => Button });
      };
    },
  });
</script>
