import {
  LoginParams,
  LoginResultModel,
  GetUserInfoByUserIdParams,
  GetUserInfoByUserIdModel,
} from "./model/userModel";
import { defHttp } from "/@/utils/http/axios";
import { ErrorMessageMode } from "/@/utils/http/axios/types";

enum Api {
  Login = "/auth/login",
  GetUserInfoById = "/users",
}

/**
 * @description: user login api
 */
export function loginApi(params: LoginParams, mode: ErrorMessageMode = "modal") {
  return defHttp.post<LoginResultModel>(
    {
      url: Api.Login,
      params,
    },
    {
      errorMessageMode: mode,
    }
  );
}

/**
 * 更新某个专业的 备注
 * @param pageId
 * @param pageNote
 */
export function updatePageNote(pageId: string, pageNote: string) {
  return defHttp.patch({
    url: `/pages/${pageId}`,
    params: {
      note: pageNote,
    },
  });
}

/**
 * 获取用户id
 * @param userId
 */
export function getMenuListById(userId: string) {
  return defHttp.get({
    url: `/users/${userId}/menuLists`,
  });
}

/**
 * @description: getUserInfoById
 */
export function getUserInfoById(params: GetUserInfoByUserIdParams) {
  return defHttp.get<GetUserInfoByUserIdModel>({
    url: `/users/${params._id}`,
  });
}
