import { setErrorNotify } from "/@/api/users/users";
import { userStore } from "/@/store/modules/user";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export function base64ToUint8Array(base64String) {
  let padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  let base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  let rawData = atob(base64);
  let outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i++) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function uint8ArrayToBase64(arr) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(arr)));
}

export async function getFingerprint() {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;
  const result = await fp.get();
  return result.visitorId;
}

export async function getPushSubscriptionFormatData(pushSubscription) {
  return {
    endpoint: pushSubscription.endpoint,
    finger_print: await getFingerprint(),
    keys: {
      p256dh: uint8ArrayToBase64(pushSubscription.getKey("p256dh")),
      auth: uint8ArrayToBase64(pushSubscription.getKey("auth")),
    },
  };
}

export function detectUserSubscription(publicKey: Nullable<string> = null): Promise<any> {
  publicKey = publicKey ?? userStore.getUserInfoState.public_key;
  return new Promise((res, rej) => {
    window.navigator.serviceWorker.ready.then((registration) => {
      return registration.pushManager
        .getSubscription()
        .then((subscription) => {
          if (subscription) {
            return subscription;
          }

          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: publicKey,
          });
        })
        .catch((err) => {
          rej(err);
        })
        .then(async (subscription) => {
          res(await getPushSubscriptionFormatData(subscription));
        });
    });
  });
}

/**
 * 检测用户是否已订阅
 * @param userId
 * @param publicKey
 */
export function detectUserHasSubed(userId: string, publicKey: string) {
  return new Promise((res, rej) => {
    window.navigator.serviceWorker.ready.then((registration) => {
      return registration.pushManager.getSubscription().then((subscription) => {
        if (subscription) {
          res(getPushSubscriptionFormatData(subscription));
        } else {
          registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: publicKey,
            })
            .then((subscription) => {
              res(getPushSubscriptionFormatData(subscription));
            })
            .catch((err) => {
              rej(err);
            });
        }
      });
    });
  });
}

/**
 * 检查用户是否开启通知, 并且自动更新
 * @param userId
 * @param publicKey
 */
export function checkUserSubscription(userId: string, publicKey: string) {
  detectUserHasSubed(userId, publicKey).then(
    async (res) => {
      setErrorNotify(userStore.getUserId, {
        is_error_notify: true,
        ...res,
      });
    },
    (err) => {
      console.log(err);
    }
  );
}
