import { useGlobSetting } from "/@/hooks/setting";
import { useI18n } from "/@/hooks/web/useI18n";
import { REDIRECT_NAME } from "/@/router/constant";
import { useTitle as usePageTitle } from "@vueuse/core";
import { watch, unref } from "vue";
import { useRouter } from "vue-router";

export function useTitle() {
  const { title } = useGlobSetting();
  const { t } = useI18n();
  const { currentRoute } = useRouter();

  const pageTitle = usePageTitle();

  watch(
    () => currentRoute.value.path,
    () => {
      const route = unref(currentRoute);
      if (route.name === REDIRECT_NAME) {
        return;
      }

      const tTitle = t(route?.meta?.title as string);
      pageTitle.value = tTitle ? ` ${tTitle} - ${title} ` : `${title}`;
    },
    { immediate: true }
  );
}
