import { FlowsBulkType, FlowsListResultModel } from "/@/api/automation/model/flowsModel";
import { IGetApiParams } from "/@/api/sys/model/admin-permissions-mode";
import { defHttp } from "/@/utils/http/axios";

enum Api {
  basicPath = "/flows",
}

/**
 * 跨平台跨专页复制节点
 */
export function PostCopyNodes(params: {
  page_id: string;
  from_site: string;
  flow_id: string;
  to_namespace: string;
  nodes: any[];
}) {
  return defHttp.post<any>({
    url: `${Api.basicPath}/copy`,
    params,
  });
}

/**
 * 获取flows列表
 */
export function getFlowsList(params: IGetApiParams) {
  return defHttp.get<FlowsListResultModel>({
    url: Api.basicPath,
    params,
  });
}

/**
 * 重命名一个flow
 * @param id
 * @param name
 */
export function changeFlowName(id: string, name: string) {
  return defHttp.put({
    url: `${Api.basicPath}/${id}/rename`,
    params: {
      name,
    },
  });
}

/**
 * 软删除一个flow
 * @param namespace
 */
export function deleteFlowSoft(namespace: string) {
  return defHttp.delete({
    url: `${Api.basicPath}/${namespace}/delete`,
  });
}

/**
 * 硬删除一个flow
 * @param namespace
 */
export function deleteFlowHard(namespace: string) {
  return defHttp.delete({
    url: `${Api.basicPath}/${namespace}/permanentlyDelete`,
  });
}

/**
 * 还原一个工作流
 * @param namespace
 */
export function restoreFlow(namespace: string) {
  return defHttp.post({
    url: `${Api.basicPath}/restore`,
    params: {
      namespace: namespace,
    },
  });
}

/**
 * 批量操作flow
 * @param payload
 */
export function flowsBulk(payload: { namespace: string[]; type: FlowsBulkType }) {
  return defHttp.post({
    url: `${Api.basicPath}/bulk`,
    params: payload,
  });
}

/**
 * 创建一个flow
 * @param payload
 */
export function createNewFlow(payload: { name: string }) {
  return defHttp.post({
    url: Api.basicPath,
    params: payload,
  });
}

/**
 * 编辑flow
 * @param id
 */
export function getFlowEdit(id: string) {
  return defHttp.get({
    url: `${Api.basicPath}/${id}/edit`,
  });
}

/**
 * 获取共享工作流预览数据
 * @param hashId
 */
export function getFlowSharePreview(hashId: string) {
  return defHttp.get({
    url: `${Api.basicPath}/sharePreview`,
    params: {
      share_hash: hashId,
    },
  });
}

/**
 * 保存草稿
 * @param id
 * @param params
 */
export function saveDraftFlow(id: string, params) {
  return defHttp.put({
    url: `${Api.basicPath}/${id}/setDraftBatch`,
    params,
  });
}

/**
 * 发布工作流
 * @param id
 * @param params
 */
export function publishFlow(id: string, params) {
  return defHttp.put({
    url: `${Api.basicPath}/${id}/publish`,
    params,
  });
}

/**
 * 恢复到发布状态
 * @param id
 */
export function revertPublished(id: string) {
  return defHttp.post({
    url: `${Api.basicPath}/${id}/revertPublished`,
  });
}

/**
 * 复制flow
 * @param id
 */
export function cloneFlow(id: string) {
  return defHttp.post({
    url: `${Api.basicPath}/${id}/clone`,
  });
}

/**
 * 获取flow分享链接
 * @param flowId
 */
export function getFlowShareLink(flowId: string) {
  return defHttp.get({
    url: `${Api.basicPath}/${flowId}/shareLink`,
  });
}

/**
 * 保存分享的flow到我的页面
 * @param shareHash
 * @param pageId
 */
export function saveShareFlowToPage(shareHash: string, pageId: string) {
  return defHttp.post({
    url: `${Api.basicPath}/shareImport?share_hash=${shareHash}`,
    params: {
      share_hash: shareHash,
      page_id: pageId,
    },
  });
}
