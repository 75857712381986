import { defHttp } from "/@/utils/http/axios";
import { IFields } from "/@/views/settings/fields/types";
import { FieldsType } from "/@/views/settings/fields/types";

enum Fields {
  basicPath = "/fields",
}

/**
 * 获取可用的字段列表(用户输入时使用, 非列表使用)
 * @param range
 */
export function getAvailableFields(range: "all" | FieldsType.CUSTOM) {
  return defHttp.get({
    url: `${Fields.basicPath}/available`,
    params: {
      range,
    },
  });
}

/**
 * 获取fields列表
 * @param params
 */
export function getFieldsList(params) {
  return defHttp.get<IFields[]>({
    url: Fields.basicPath,
    params,
  });
}

/**
 * 创建一个fields
 * @param params
 */
export function createField(params: IFields) {
  return defHttp.post({
    url: Fields.basicPath,
    params: params,
  });
}

/**
 * 更新一个 fields
 * @param params
 */
export function updateField(params: IFields) {
  return defHttp.put({
    url: `${Fields.basicPath}/${params._id}`,
    params: params,
  });
}

/**
 * 归档一个field
 * @param id
 */
export function archiveField(id: string) {
  return defHttp.put({
    url: `${Fields.basicPath}/${id}/archive`,
  });
}

/**
 * 还原一个field
 * @param id
 */
export function restoreField(id: string) {
  return defHttp.put({
    url: `${Fields.basicPath}/${id}/restore`,
  });
}

/**
 * 删除一个field
 * @param id
 */
export function deleteField(id: string) {
  return defHttp.delete({
    url: `${Fields.basicPath}/${id}`,
  });
}
