import { TimeOfIntervalPickerType } from "/@/components/ChatBot/Common/TimeOfIntervalPicker/types";
import { IFlowItem } from "/@/views/automation/types";
import { WidgetAttachStatusEnum } from "/@/views/widgets/edit/components/setup/components/widgetType/comment/components/attach/types";

export enum WidgetStatus {
  DRAFT = "draft",
  ACTIVE = "active",
}

interface IWidgetIconConfig {
  color: string;
  size: number;
  largeSize: number;
}

export const widgetsIconConfig: IWidgetIconConfig = {
  color: "#1890ff",
  size: 24,
  largeSize: 32,
};

export enum WidgetsType {
  COMMENT = "feed_comment_trigger",
  REF = "messenger_ref_url",
  IG_STORY_REPLY = "ig_story_reply",
}

export type WidgetsListItem = {
  icon: string;
  text: string;
  value: string;
  loading?: boolean;
};

interface IWidgetsList {
  [propName: string]: WidgetsListItem;
}

type facebookPostItem = {
  id: string;
  is_eligible_for_promotion: boolean;
  status_type: string;
  picture: string;
  full_picture: string;
  permalink_url: string;
  created_time: string;
  is_hidden: boolean;
  is_published: boolean;
  promotable_id: string;
};

type WidgetsDataRef = {
  set_up: {
    ref: string;
    ref_payload_field_id: number;
  };
};

type messagesMapping = {
  id: number;
  content: string;
};

export type WidgetsDataComment = {
  feed_comment_settings: {
    posts: string[];
    eula: boolean;
    exclude_keywords: string;
    include_keywords: string;
    track_root_comment_only: boolean;
    track_all_posts?: boolean;
    only_unsubscribed?: boolean;
    only_first_comment?: boolean;
  };
  feed_comment_welcome: {
    public_reply_messages: string[];
    comment_num_once: number | "random";
    public_reply_messages_mapping?: messagesMapping[];
    like_user_comment: boolean;
    random_delay: boolean;
    delay_time: TimeOfIntervalPickerType;
    random_min_comment_num: number;
    random_max_comment_num: number;
    max_daily_reply_num?: number | null;
    max_reply_num_one_minute?: number | null;
    max_daily_msg_num?: number | null;
    max_msg_num_one_minute?: number | null;
  };
};

export type WidgetsDataIgStoryReply = {
  auto_like: boolean;
  story_ids: string[];
  all_story: boolean;
  keywords: string;
  delay: number;
};

export interface IWidget {
  _id: string;
  name: string;
  page_id?: number;
  widget_type: WidgetsType;
  messenger_ref_url?: string;
  status: WidgetStatus;
  setting_data: WidgetsDataRef | WidgetsDataComment | WidgetsDataIgStoryReply;
  flow: IFlowItem;
  attach?: {
    site: string;
    widget: {
      _id: string;
      name: string;
    };
    is_active: boolean;
    status: WidgetAttachStatusEnum;
    duration: {
      value: number; // 2 <= value <= 10，默认是 5 分钟
      unit: "minute"; // 只能是分钟
    };
  };
}

export type StoryType = {
  id: string;
  thumbnail_url: string;
  caption: string;
};

export const widgetsList: IWidgetsList = {
  [WidgetsType.COMMENT]: {
    icon: "ant-design:comment-outlined",
    text: "评论",
    value: WidgetsType.COMMENT,
  },
  [WidgetsType.REF]: {
    icon: "codicon:link",
    text: "Ref",
    value: WidgetsType.REF,
  },
  [WidgetsType.IG_STORY_REPLY]: {
    icon: "mdi:instagram",
    text: "IG 快拍",
    value: WidgetsType.IG_STORY_REPLY,
  },
};
