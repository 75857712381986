import { isObject } from "/@/utils/is";
import { unref } from "vue";
import type { RouteLocationNormalized, RouteRecordNormalized } from "vue-router";

export const noop = () => {};

/**
 * @description:  Set ui mount node
 */
export function getPopupContainer(node?: HTMLElement): HTMLElement {
  return (node?.parentNode as HTMLElement) ?? document.body;
}

/**
 * Add the object as a parameter to the URL
 * @param baseUrl url
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl: string, obj: any): string {
  let parameters = "";
  for (const key in obj) {
    parameters += key + "=" + encodeURIComponent(obj[key]) + "&";
  }
  parameters = parameters.replace(/&$/, "");
  return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, "?") + parameters;
}

export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string;
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
  }
  return src;
}

export function openWindow(
  url: string,
  opt?: {
    target?: TargetContext | string;
    noopener?: boolean;
    noreferrer?: boolean;
  }
) {
  const { target = "__blank", noopener = true, noreferrer = true } = opt || {};
  const feature: string[] = [];

  noopener && feature.push("noopener=yes");
  noreferrer && feature.push("noreferrer=yes");

  window.open(url, target, feature.join(","));
}

// dynamic use hook props
export function getDynamicProps<T, U>(props: T): Partial<U> {
  const ret: Recordable = {};

  Object.keys(props).map((key) => {
    ret[key] = unref((props as Recordable)[key]);
  });

  return ret as Partial<U>;
}

export function getRawRoute(route: RouteLocationNormalized): RouteLocationNormalized {
  if (!route) return route;
  const { matched, ...opt } = route;
  return {
    ...opt,
    matched: (matched
      ? matched.map((item) => ({
          meta: item.meta,
          name: item.name,
          path: item.path,
        }))
      : undefined) as RouteRecordNormalized[],
  };
}

/**
 * 获取 search query params
 * @param paramsString
 */
export function getSearchParams(paramsString: string = null): any {
  paramsString = paramsString ?? window.location.hash.slice(1);
  let searchParams = new URLSearchParams(paramsString);
  let obj = {};

  for (let [key, value] of searchParams) {
    obj[key] = value;
  }

  return obj;
}

/**
 * 写入样式
 * @param style
 * @param id
 */
export function writeStyle(style: string, id = "writeStyle") {
  let styleEl = document.querySelector(`#${id}`);
  if (styleEl) {
    styleEl.innerHTML = style;
    return;
  }

  styleEl = document.createElement("style") as HTMLStyleElement;
  styleEl.id = id;
  styleEl.innerHTML = style;
  (document.querySelector("head") as HTMLHeadElement).appendChild(styleEl);
}

/**
 * 监听 el 是否出现
 * @param el
 * @param threshold
 * @param callback
 */
export function observeEl(el, threshold, callback) {
  let observer = new IntersectionObserver(
    function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting && entry.intersectionRatio >= threshold / 100) {
          callback();
          observer.disconnect();
        }
      });
    },
    { threshold: [threshold / 100] }
  );
  observer.observe(el);

  return function () {
    observer.disconnect();
  };
}
