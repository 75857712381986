import App from "./App.vue";
import "./assets/tailwindcss.output.css";
//  dayjs
import "./dayjs";
import { registerGlobComp } from "/@/components/registerGlobComp";
import "/@/design/index.less";
import { setupGlobDirectives } from "/@/directives";
import { setupEventCenter } from "/@/global/EventCenter";
import { setupI18n } from "/@/locales/setupI18n";
import { setupErrorHandle } from "/@/logics/error-handle";
//  service worker
import "/@/register-service-worker";
import router, { setupRouter } from "/@/router";
// router-guard
import "/@/router/guard";
import { setupStore } from "/@/store";
import { createApp } from "vue";

//  如果本地环境使用测试环境的 AppID
window.appId = window.appId === "APP_ID_SLOT" ? "657472112718791" : window.appId;

// Do not introduce` on-demand in local development?
// In the local development for on-demand introduction, the number of browser requests will increase by about 20%.
// Which may slow down the browser refresh.
// Therefore, all are introduced in local development, and only introduced on demand in the production environment
if (import.meta.env.DEV) {
  import("ant-design-vue/dist/antd.less");
}

(async () => {
  setupEventCenter();

  const app = createApp(App);
  // Register global components
  registerGlobComp(app);

  // Multilingual configuration
  await setupI18n(app);

  // Configure routing
  setupRouter(app);

  // Configure vuex store
  setupStore(app);

  // Register global directive
  setupGlobDirectives(app);

  // Configure global error handling
  setupErrorHandle(app);

  // Mount when the route is ready
  // https://next.router.vuejs.org/api/#isready
  await router.isReady();

  app.mount("#app", true);

  if (import.meta.env.DEV) {
    window.__APP__ = app;
  }
})();
