import store from '/@/store/index';
import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import { hotModuleUnregisterModule } from '/@/utils/helper/vuexHelper';
import { IRolePermission } from '/@/views/admin-roles/types';
import { getRolePermissionList } from '/@/api/sys/admin-roles';

const NAME = 'roles';
hotModuleUnregisterModule(NAME);

@Module({ namespaced: true, name: NAME, dynamic: true, store })
class Roles extends VuexModule {
  private roles: IRolePermission[] = [];

  get getRoles() {
    return this.roles;
  }

  @Mutation
  commitSetRoles(roles: IRolePermission[]) {
    this.roles = roles;
  }

  @Action
  async loadRoles() {
    let { items } = await getRolePermissionList({ page: 1, pageSize: 999 });
    this.commitSetRoles(items);
  }
}

export const rolesStore = getModule<Roles>(Roles);
