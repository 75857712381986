import { intersection } from "lodash-es";
import { useTabs } from "./useTabs";
import { PermissionModeEnum } from "/@/enums/appEnum";
import { RoleEnum } from "/@/enums/roleEnum";
import router, { resetRouter } from "/@/router";
// import { RootRoute } from '/@/router/routes';
import projectSetting from "/@/settings/projectSetting";
import { appStore } from "/@/store/modules/app";
import { permissionStore } from "/@/store/modules/permission";
import { tabStore } from "/@/store/modules/tab";
import { userStore } from "/@/store/modules/user";
import { isArray } from "/@/utils/is";
import type { RouteRecordRaw } from "vue-router";

// User permissions related operations
export function usePermission() {
  /**
   * Change permission mode
   */
  async function togglePermissionMode() {
    appStore.commitProjectConfigState({
      permissionMode:
        projectSetting.permissionMode === PermissionModeEnum.BACK
          ? PermissionModeEnum.ROLE
          : PermissionModeEnum.BACK,
    });
    location.reload();
  }

  /**
   * Reset and regain authority resource information
   * @param id
   */
  async function resume(id?: string | number) {
    tabStore.commitClearCache();
    resetRouter();
    const routes = await permissionStore.buildRoutesAction(id);
    routes.forEach((route) => {
      router.addRoute(route as unknown as RouteRecordRaw);
    });
    permissionStore.commitLastBuildMenuTimeState();
    const { closeAll } = useTabs();
    closeAll();
  }

  /**
   * Determine whether there is permission
   */
  function hasPermission(value?: RoleEnum | RoleEnum[] | string | string[], def = true): boolean {
    const permMode = projectSetting.permissionMode;
    if (PermissionModeEnum.ROLE === permMode) {
      // Visible by default
      if (!value) {
        return def;
      }
      if (!isArray(value)) {
        return userStore.getRoleListState?.includes(value as RoleEnum);
      }
      return (intersection(value, userStore.getRoleListState) as RoleEnum[]).length > 0;
    }
    if (PermissionModeEnum.BACK === permMode) {
      // Visible by default
      if (!value) {
        return def;
      }
      //  此处设置权限
      const allCodeList = [];
      if (!isArray(value)) {
        return allCodeList.includes(value as string);
      }
      return (intersection(value, allCodeList) as string[]).length > 0;
    }
    return true;
  }

  /**
   * Change roles
   * @param roles
   */
  async function changeRole(roles: RoleEnum | RoleEnum[]): Promise<void> {
    if (projectSetting.permissionMode !== PermissionModeEnum.ROLE) {
      throw new Error(
        "Please switch PermissionModeEnum to ROLE mode in the configuration to operate!"
      );
    }
    if (!isArray(roles)) {
      roles = [roles];
    }
    userStore.commitRoleListState(roles);
    await resume();
  }

  /**
   * Change menu
   */
  async function changeMenu(id?: string | number) {
    // TODO The id passed in here is for testing. Actually, you don’t need to pass it. The id of the login person will be automatically obtained.
    resume(id);
  }

  return { changeRole, hasPermission, togglePermissionMode, changeMenu };
}
