/**
 * 处理事件中心
 */
import EventEmitter from "eventemitter3";

export type ConnectionUpdatePayload = {
  inputNodeId: string;
  outputConnectionId: string;
  outputNodeId: string;
};

export enum EventNameEnum {
  //  工作流编辑 发送消息 节点时的 container scroll 事件
  //  用于 UserInput 类型的 widget 的 Popover 处理滚动定位
  SEND_MESSAGE_PANEL_SCROLL = "sendMessagePanelScroll",
  //  工作流中的 node 发生了改变
  FLOW_NODE_UPDATE = "flowNodeUpdate",
  //  工作流中 connection 创建
  FLOW_CONNECTION_CREATED = "flowConnectionCreated",
  //  工作流中 connection 移除
  FLOW_CONNECTION_REMOVED = "flowConnectionRemoved",
  //  检查工作流中 Send_message 节点中 UserInput 组件是否有 Error
  FLOW_SEND_MESSAGE_USER_INPUT_ERROR_CHECK = "flowSendMessageUserInputErrorCheck",
}

export type EventTypes = {
  [EventNameEnum.SEND_MESSAGE_PANEL_SCROLL]: () => void;
  [EventNameEnum.FLOW_NODE_UPDATE]: () => void;
  [EventNameEnum.FLOW_CONNECTION_CREATED]: (payload: ConnectionUpdatePayload) => void;
  [EventNameEnum.FLOW_CONNECTION_REMOVED]: (payload: ConnectionUpdatePayload) => void;
  [EventNameEnum.FLOW_SEND_MESSAGE_USER_INPUT_ERROR_CHECK]: () => void;
};

export function setupEventCenter() {
  window.__event_center = new EventEmitter<EventTypes>();
}
