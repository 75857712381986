export enum PageEnum {
  // basic login path
  BASE_LOGIN = "/login",
  // basic home path
  BASE_HOME = "/home",
  // error page path
  ERROR_PAGE = "/exception",
  // error log page path
  ERROR_LOG_PAGE = "/error-log/list",
  WIDGETS = "/widgets",
  FLOWS = "/automation/flows",
}
