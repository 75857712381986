import { mainOutRoutes } from "./mainOut";
import { PageEnum } from "/@/enums/pageEnum";
import { t } from "/@/hooks/web/useI18n";
import { PAGE_NOT_FOUND_ROUTE, REDIRECT_ROUTE } from "/@/router/routes/basic";
import type { AppRouteRecordRaw } from "/@/router/types";
import { isDevMode } from "/@/utils/env";

export const asyncRoutes = [PAGE_NOT_FOUND_ROUTE];

export const RootRoute: AppRouteRecordRaw = {
  path: "/",
  name: "Root",
  redirect: PageEnum.BASE_HOME,
  meta: {
    title: "Root",
  },
};

export const LoginRoute: AppRouteRecordRaw = {
  path: "/login",
  name: "Login",
  component: () => import("/@/views/sys/login/Login.vue"),
  meta: {
    title: t("routes.basic.login"),
  },
};

// Basic routing without permission
const basicRoutes = [LoginRoute, RootRoute, ...mainOutRoutes, REDIRECT_ROUTE];

basicRoutes.push({
  path: "/guest/home",
  name: "gustHome",
  component: () => import("/@/views/guest/index.vue"),
  meta: {
    title: "分享链接",
    ignoreAuth: true,
  },
});

if (isDevMode()) {
  basicRoutes.push({
    path: "/playground",
    name: "Playground",
    component: () => import("/@/views/playground/index.vue"),
    meta: {
      title: "playground",
    },
  });
}

export { basicRoutes };
