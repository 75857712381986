import { defHttp } from "/@/utils/http/axios";
import { ITags } from "/@/views/settings/tags/types";

enum Api {
  basicPath = "/tags",
}

/**
 * 获取tags列表
 * @param params
 */
export function getTags(params) {
  return defHttp.get<ITags[]>({
    url: Api.basicPath,
    params,
  });
}

/**
 * 创建一个tag
 * @param name
 * @param is_label
 */
export function createTag(name: string, is_label: boolean = false) {
  return defHttp.post({
    url: Api.basicPath,
    params: {
      name,
      is_label,
    },
  });
}

/**
 * 更新一个tag
 * @param id
 * @param name
 */
export function updateTag(id: string, payload) {
  return defHttp.put({
    url: `${Api.basicPath}/${id}`,
    params: payload,
  });
}

/**
 * 删除一个tag
 * @param id
 */
export function deleteTag(id: string) {
  return defHttp.delete({
    url: `${Api.basicPath}/${id}`,
  });
}

/**
 * 获取tag对应的列表
 * @param id
 */
export function getUsesList(id: string) {
  return defHttp.get({
    url: `${Api.basicPath}/${id}/getUsesList`,
  });
}
