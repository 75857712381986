import { CacheTypeEnum } from "/@/enums/cacheEnum";
import { TOKEN_KEY } from "/@/enums/cacheEnum";
import projectSetting from "/@/settings/projectSetting";
import { Persistent, BasicKeys } from "/@/utils/cache/persistent";

const { permissionCacheType } = projectSetting;
const isLocal = permissionCacheType === CacheTypeEnum.LOCAL;

export function getToken() {
  return getAuthCache(TOKEN_KEY);
}

export function getAuthCache<T>(key: BasicKeys) {
  const fn = isLocal ? Persistent.getLocal : Persistent.getSession;
  return fn(key) as T;
}

export function setAuthCache(key: BasicKeys, value) {
  const fn = isLocal ? Persistent.setLocal : Persistent.setSession;
  return fn(key, value, true);
}

export function deleteAllCookies() {
  document.cookie = "";
  localStorage.clear();
  sessionStorage.clear();

  try {
    window.cookieStore.getAll().then((cookies) =>
      cookies.forEach((cookie) => {
        console.log("Cookie deleted:", cookie);
        window.cookieStore.delete(cookie.name);
      })
    );
  } catch (e) {}
}
